














































import { Component, Prop, Vue } from "vue-property-decorator";
import { document_fileDto } from "@/shared/dtos/document_fileDto";
import { MessageService } from "@/shared/services/message-service";

@Component({
  components: {
    DocumentsUpload: () =>
      import("@/views/proyectos/Documentos/DocumentsUpload.vue"),
    Xbtn: () => import("@/components/buttons/x-btn.vue"),
  },
})
export default class DocumentsHandler extends Vue {
  @Prop() fileItems!: document_fileDto[];
  @Prop({ default: "" }) url_upload!: string;

  public get_icon(extension: string) {
    extension = extension.replace(".", "").toLowerCase();
    var file = this.fileIcons.find((x) => x.name === extension);
    if (file !== undefined) {
      return file.icon;
    }
    return this.fileIcons.find((x) => x.name === "other");
  }

  public delete_file(file: document_fileDto) {
    MessageService.confirm(
      "¿Quiere eliminar el fichero?",
      "Va a eliminar " + file.name,
      (cb: boolean) => {
        if (cb) {
          this.$emit("delete_file", file);
        }
      }
    );
  }
  public downlod_file(file: document_fileDto) {
    this.$emit("downlod_file", file);
  }

  private fileIcons = [
    { name: "zip", icon: "mdi-folder-zip-outline" },
    { name: "rar", icon: "mdi-folder-zip-outline" },
    { name: "json", icon: "mdi-json" },
    { name: "pdf", icon: "mdi-file-pdf" },
    { name: "png", icon: "mdi-file-image" },
    { name: "jpg", icon: "mdi-file-image" },
    { name: "jpeg", icon: "mdi-file-image" },
    { name: "mp4", icon: "mdi-filmstrip" },
    { name: "mkv", icon: "mdi-filmstrip" },
    { name: "avi", icon: "mdi-filmstrip" },
    { name: "wmv", icon: "mdi-filmstrip" },
    { name: "mov", icon: "mdi-filmstrip" },
    { name: "gif", icon: "mdi-filmstrip" },
    { name: "txt", icon: "mdi-file-document-outline" },
    { name: "xls", icon: "mdi-file-excel" },
    { name: "xlsx", icon: "mdi-file-excel" },
    { name: "txt", icon: "description" },
    { name: "doc", icon: "description" },
    { name: "docx", icon: "description" },
    { name: "rdf", icon: "description" },
    { name: "csv", icon: "mdi-file-excel" },
    { name: "other", icon: "mdi-file-outline" },
  ];
}
